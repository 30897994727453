$color-black: #393C41;
$color-offWhite: #F6F6F6;
$color-gray: #8E8E8E;
$color-primary:#689C46;
@use 'styles/reset.css';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:$color-gray;
  font-size:16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Gotham Rounded";
  src: url("../public/fonts/GothamRnd-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  /* This is optional and helps with performance */
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../public/fonts/GothamRnd-Medium.otf") format("opentype");
  font-weight: 500;
  /* SemiBold is often 600 */
  font-style: normal;
  font-display: swap;
  /* This is optional and helps with performance */
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../public/fonts/GothamRnd-Bold.otf") format("opentype");
  font-weight: 600;
  /* SemiBold is often 600 */
  font-style: normal;
  font-display: swap;
  /* This is optional and helps with performance */
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../public/fonts/GothamRnd-BookItal.otf") format("opentype");
  font-weight: normal;
  /* SemiBold is often 600 */
  font-style: italic;
  font-display: swap;
  /* This is optional and helps with performance */
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../public/fonts/GothamRnd-MedItal.otf") format("opentype");
  font-weight: 400;
  /* SemiBold is often 600 */
  font-style: italic;
  font-display: swap;
  /* This is optional and helps with performance */
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("../public/fonts/GothamRnd-BoldItal.otf") format("opentype");
  font-weight: 600;
  /* SemiBold is often 600 */
  font-style: italic;
  font-display: swap;
  /* This is optional and helps with performance */
}

body, * {
  font-family: "Gotham Rounded", sans-serif;
  font-weight: 500;
  line-height: 155%;
}

h1, h2 {
  color:$color-black;
  font-weight: 600;
  font-size:1.5em;
}

.logo {
  width:154px;
  height:48px;
  background: url(../public/logo.png) no-repeat;
  background-size: contain;
  text-indent: -999999px;
}

.centered-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width:840px;
  margin:0 auto;
  // padding-top:40px;
}

.wrapper {
  display: flex;
  align-items: stretch;
}

.main {
  padding:20px 70px;
  width:100%;
  color:$color-black;

  @media all and (max-width: 1000px) {
    padding-left:20px;
    padding-right:20px;
  }

  section {
    margin:60px 0;

      display: flex;
    // @media all and (min-width: 1001px) {
      // }

    @media all and (max-width: 1000px) {
      align-items: center;
      flex-direction: column;
      text-align: center;
      margin:40px 0;

      .upper div {
        margin:0 auto;
      }
    }

    .gutter {
      margin-right:40px;


      @media all and (max-width: 1000px) {
        margin-right:0;
      }
    }

    & > div:not(.gutter) {
      width:100%;
    }
  }
}


.section-split {
  display: flex;
  justify-content: space-between;
  gap:40px;

  .left {
    // width:70%;
    // max-width:600px;
    width:100%;
    color:$color-black;
  }

  .left .upper {
    display: flex;
    align-items: center;
  }

  .right {
    text-align: center;
    min-width:200px;

    span {
      color: $color-gray;
    }
  }

  @media all and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

$badgeSize: 100px;

.badge {
  background: url(../public/badges/2.png) no-repeat;
  background-size:cover;
  width:$badgeSize;
  height:$badgeSize;
  margin-right:20px;
  flex-shrink:0;
}

.badges {
  background: url(../public/badges/badges-1-unlocked.png) center no-repeat;
  background-size:contain;
  width:$badgeSize * 1.3;
  height:$badgeSize * 1.3;
  flex-shrink:0;
}

.link {
  background-color:$color-offWhite;
  width:100%;
  border-radius:8px;
  padding:20px;
  color:$color-black;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size:1.6em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  gap:24px;

  span {
    font-size:15px;
    margin-left: 20px;
    color:#8E8E8E
  }

  &:hover {
    background-color: darken($color-offWhite, 5%);
  }
}

.link span {
  font-size:15px;
  margin-left: 20px;
  color:#8E8E8E
}

.metadata-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width:700px;
  margin:0 auto;
}

.email-notice {
  width:230px;
  height:55px;
  background: url(../public/email-confirm-notice.png) no-repeat;
  background-size: contain;
  text-indent: -99999px;
}

.email-figure {
  $size:120px;
  width:$size;
  height:calc($size / .95);
  background: url(../public/email-confirm-figure.png) no-repeat;
  background-size: contain;
} 

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:20px 40px;
  max-width:1920px;
}

@media all and (max-width: 760px) {
  .header {
    padding:10px;
  }
}

.split {
  display: flex;
  text-align: left;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.sidebar {
  $sidePadding:60px;

  flex-shrink:0;
  background-color: #FBFBFB;
  background: url(../public/bg.png) no-repeat #FDFEFB;
  background-size:cover;

  @media screen and (min-width: 1001px) {
    width:25%;
    min-height:100vh;
  }

  .milestones {
    // margin:60px 0;
    padding:40px $sidePadding;
  }

  .position {
    // padding-bottom: 20px;
    color:$color-black;

    .text-xl {
      font-size: 2.8em;
      line-height: 1.2em;
      font-weight: bold !important;
      transition:transform .3s cubic-bezier(0.075, 0.82, 0.165, 1);

      &.active {
        color:$color-primary;
        transform: scale(1.1);
      }
    }
    
    & > div {
      padding:0 $sidePadding;
      margin-bottom: 40px;
    }

    &:after {
      content:'';
      display: block;
      height:4px;
      width:100%;
      background: url(../public/progress-bar.png) no-repeat;
      background-size:cover;
      margin:20px 0;
    }
  }

  @media all and (max-width: 1000px) {
    .logo {
      margin:30px $sidePadding !important;
    }

    .position > div {
      margin-bottom: 0;
    }

    .milestones {
      padding:10px $sidePadding;
    }
  }

  .milestones > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:15px;
    margin-bottom: 20px;
    
    &:before {
      content:'';
      display: block;
      width:40px;
      height:40px;
      background: url(../public/circle-check.svg) no-repeat;
      background-size:cover;
      flex-shrink: 0;
    }

    & > div:first-child {
      width:100%;
      color:$color-black;
    }
  }

  .logo {
    margin:40px $sidePadding;
  }
}

// .big-button {
//   background-color: #5196FF;
//   color: white;
//   border: none;
//   border-radius: 8px;
//   padding: 20px 34px;
//   cursor: pointer;
//   transition: all 0.3s ease;
//   text-decoration: none;
//   display: flex;
//   align-items: center;
//   gap:50px;
//   position: relative;
//   margin:10px;
// }

// .big-button a {
//   position: absolute;
//   left:0;
//   top:0;
//   display: block;
//   width:100%;
//   height:100%;
//   text-indent: -999999px;
// }

// .big-button div {
//   text-align: left;
// }

// .big-button .congrats {
//   width:40px;
//   height:40px;
//   background: url(../public/icon-share.svg) no-repeat;
//   background-size: contain;
// }

// .big-button:hover {
//   background-color: #3A7ACD;
// }

// .big-button .line-1 {
//   font-size:30px;
//   line-height:34px;
//   display: block;
// }

// @media screen and (max-width: 760px) {
//   .big-button {
//     padding:12px 16px;
//   }

//   .big-button .line-1 {
//     font-size:22px;
//   }
  
// }

h1, h2 {
  /* line-height: 0; */
  margin:0;
}
/* 
.milestones {
  display: flex;
  align-items: center;
  gap:15px;
  margin:60px 0;
}

.milestones .item.active {
  color:#5196FF
}

.milestones .item figure {
  width:80px;
  height:80px;
  margin:0;
  background-size: contain;
  background-position: center center;
  margin-bottom:20px;
  transform:scale(1.5)
}

.milestones .bar {
  height:4px;
  width:50px;
  background-color: #D9D9D9;
  position: relative;
  top:-30px;
}

.milestones .bar .half-bar:after {
  position: absolute;
  content: "";
  background-color: #5196FF;
  width:50%;
  height:100%;
  left:0;
}

.milestones .bar .full:after {
  position: absolute;
  content: "";
  background-color: #5196FF;
  width:100%;
  height:100%;
  left:0;
} */


input, button, .button {
  border-radius: 8px;
  padding: 10px 20px;
  font-size:1em;
}

input {
  border: none;
  outline: none;
  border-radius: 8px;
  margin-right:4px;
  outline:3px solid transparent;
}

input:focus {
  outline-color: #689C46;
}

button, .button {
  background-color: $color-offWhite;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  color: $color-black;
  border:none;
}

button:after, .button:after {
  content: "→";
  margin-left: 10px;
}

button:hover, .button:hover {
  background-color: darken($color-offWhite, 5%);
}

button.primary,
.button.primary {
  background-color: #689C46;
  color: white;
}

.share-buttons div {
  display: flex;
  gap: 20px;
}

.mobile-only {
  display:none;
}

.desktop-only {
  display:none;
}

@media all and (min-width:761px) {
  .desktop-only {
    display:block;
  }
  
}

@media all and (max-width:760px) {
  .link {
    font-size: 14px;
  }

  // .milestones {
  //   flex-direction: column;
  //   align-items: center;
  //   gap:8px;

  // }
  // .milestones .bar {
  //   height:20px;
  //   width:4px;
  //   margin-bottom:20px;
  //   top:0;
  // }
  // .milestones .bar.half-bar:after {
  //   width:100%;
  //   height:50%;
  // }

  .share-buttons div {
    flex-direction: column;
    align-items: center;
  }
}

.emailBox {
  background-color:#EDEDED;
  border-radius:8px;
  padding:10px 20px;
  margin:12px 0;
}

.emailUndeliveredButton {
  background-color:#F3BE4F;
  color:$color-black;
  border-radius:8px;
  padding:12px 20px;
  margin:12px 0;
  cursor: pointer;
  text-align: center;
}

.emailUndeliveredButton:hover {
  opacity: .8;
}

.notSeeingNote {
  position: fixed;
  bottom:20px;
  right:20px;
  visibility: hidden;
  opacity: 0;
  max-width:400px;
  text-align: left;
  padding:12px 20px;
  background-color:#EDEDED;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0,0,0,.1);
  transition:all .2s ease-in-out;

  @media all and (max-width: 460px) {
    input {
      margin-bottom: 10px;
    }
    input, button {
      width:100%;
    }
  }
}


@media all and (max-width: 460px) {
  .notSeeingNote {
    display:block;
    right:0;
    bottom:0;
    margin:20px;
  }
}

.notSeeingNote h3 {
  color:$color-black;

}

.notSeeingNote.active {
  opacity: 1;
  visibility: visible;
}


@media all and (max-width: 400px) {
  .mobile-only {
    display:block;
  }
}

hr {
  border-top: 1px solid #eee;
}

#boosts form {
  display: flex;

  button {
    flex-shrink: 0;
  }

  input {
    background-color: #fafafa;
  }


  @media all and (max-width: 1000px) {
    flex-direction: column;
    gap:20px;
  }

}

.copy-icon {
  width:40px;
  height:40px;
  background: url(../public/icon-copy.png) no-repeat;
  background-size: contain;

  &.active {
    background-image: url(../public/icon-copy-success.png);
  }
}

.modal {
  width:70%;
  // height: 70%;
  background-color: white;
  border-radius: 12px;
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding:40px 0;

  @media screen and (max-width: 1000px) {
    width:90%;
    // height: 90%;
  }
}

.modal-bg {
  background-color: rgba(192, 192, 192, 0.5);
  position: fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  backdrop-filter: blur(10px);

}